import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
//import { useNavigate } from 'react-router-dom'

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
         // ** Get token from localStorage
         const accessToken = this.getToken()

         // ** If token is present add it to request's Authorization Header
         if (accessToken && !config.url.includes('refresh-token')) {
            // ** eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
         }

         if (process.env.REACT_APP_NODE_ENV === 'development') {
            const url = config.url.split('api')
            console.log('request ->', url[1], config)
         }

         return config
      },
      error => Promise.reject(error)
    )

   // ** Add request/response interceptor
   axios.interceptors.response.use(
      response => response,
      async error => {

         const originalRequest = error.config

         if (error?.response?.status === 402 || (error.code === "ERR_NETWORK" && error.response === undefined)) {
            this.logoff()
            return Promise.reject(error)
         }

         if (error.response.status === 401 && !error.response.data.error.includes('error.login')) {

            if (!this.isAlreadyFetchingAccessToken) {
               this.isAlreadyFetchingAccessToken = true

               this.refreshToken().then(r => {
                  this.isAlreadyFetchingAccessToken = false
                  this.setToken(r.data[this.jwtConfig.storageTokenKeyName])
                  this.setRefreshToken(r.data[this.jwtConfig.storageRefreshTokenKeyName])
                  this.onAccessTokenFetched(r.data[this.jwtConfig.storageTokenKeyName])
               })
            }

            const retryOriginalRequest = new Promise(resolve => {
               this.addSubscriber(accessToken => {
                  originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                  resolve(axios(originalRequest))
               })
            })

            return retryOriginalRequest
         }

         return Promise.reject(error)

      })
   }

   logoff() {
      localStorage.removeItem('userData')
      localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
      document.location.href = '/login'
   }

   forceRefreshToken(originalRequest) {

      if (!this.isAlreadyFetchingAccessToken) {
         this.isAlreadyFetchingAccessToken = true

         this.refreshToken().then(r => {
            this.isAlreadyFetchingAccessToken = false
            // ** Update accessToken in localStorage
            this.setToken(r.data[this.jwtConfig.storageTokenKeyName])
            this.setRefreshToken(r.data[this.jwtConfig.storageRefreshTokenKeyName])
            this.onAccessTokenFetched(r.data[this.jwtConfig.storageTokenKeyName])
         })
      }

      const retryOriginalRequest = new Promise(resolve => {
         this.addSubscriber(accessToken => {
            originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
            resolve(axios(originalRequest))
         })
      })

      return retryOriginalRequest
   }

   onAccessTokenFetched(accessToken) {
      this.subscribers = this.subscribers.filter(callback => callback(accessToken))
   }

   addSubscriber(callback) {
      this.subscribers.push(callback)
   }

   getToken() {
      return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
   }

   getRefreshToken() {
      return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
   }

   setToken(value) {
      localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
   }

   setRefreshToken(value) {
      localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
   }

   login(...args) {
      return axios.post(this.jwtConfig.loginEndpoint, ...args)
   }

   register(...args) {
      return axios.post(this.jwtConfig.registerEndpoint, ...args)
   }

   async refreshToken() {
      return await axios.post(this.jwtConfig.refreshEndpoint, {
         refreshToken: this.getRefreshToken()
      })
   }
}
