// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

// ** Profile file actions
export const getUserAvatar = createAsyncThunk('appFiles/getUserAvatar', async (id) => {
   const response = await axios.post(apiLink('getFiles', {typeOwner: 'USER'}), {ids: [id], types: ['AVATAR']})
   return response.data.files
})
export const getCustomerAvatar = createAsyncThunk('appFiles/getCustomerAvatar', async (id) => {
   const response = await axios.get(apiLink({name: 'getFile', value: id}, {type: 'PERSONAL_PHOTO', typeOwner: 'CUSTOMER'}))
   return response.data.file
})

export const addUserAvatar = createAsyncThunk('appFiles/addUserAvatar', async ({id, type, typeOwner, file}) => {
   const response = await axios({
     method: "post",
     url: apiLink({name: 'profileUploadAvatar', value: id}, {type, typeOwner}),
     data: { file },
     headers: { "Content-Type": "multipart/form-data" }
   })
   return response.data
})
export const removeUserAvatar = createAsyncThunk('appFiles/removeUserAvatar', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeFile', value: id}, {typeOwner: 'USER'}))
   return {id, status: response.status}
})

// ** Customer file actions
export const getCustomerFile =  createAsyncThunk('appFiles/getCustomerFile', async ({id, type, owner}) => {
   const response = await axios.get(apiLink({name: 'getFile', value: id}, {type, typeOwner: owner}))
   return response.data.file
})

export const getCustomerFilesByParams = createAsyncThunk('appFiles/getCustomerFilesByParams', async ({ids, types, owner}) => {
   const response = await axios.post(apiLink('getFiles', {typeOwner: owner}), {
      ids,
      types
   })
   return response.data.files
})
export const addCustomersFilePlaceholder = createAsyncThunk('appFiles/addCustomersFilePlaceholder', (file) => {
   return file
})

// ** Car file actions
export const getCarFile =  createAsyncThunk('appFiles/getCarFile', async ({id, type, owner}) => {
   const response = await axios.get(apiLink({name: 'getFile', value: id}, {type, typeOwner: owner}))
   return response.data.file
})

export const getCarFilesByParams = createAsyncThunk('appFiles/getCarFilesByParams', async ({ids, types, owner}) => {
   const response = await axios.post(apiLink('getFiles', {typeOwner: owner}), {
      ids,
      types
   })
   return response.data.files
})
export const addCarsFilePlaceholder = createAsyncThunk('appFiles/addCarsFilePlaceholder', (file) => {
   return file
})

// ** Insurance file actions
export const getInsuranceFilesByParams = createAsyncThunk('appFiles/getInsuranceFilesByParams', async ({ids, types, owner}) => {
   const response = await axios.post(apiLink('getFiles', {typeOwner: owner}), {
      ids,
      types
   })
   return response.data.files
})
export const uploadInsuranceFile = createAsyncThunk('appFiles/uploadInsuranceFile', async ({insurance_id, type, typeOwner, file}) => {
   const response = await axios({
        method: "post",
        url: apiLink({name: 'customerUploadFile', value: insurance_id}, {type, typeOwner}),
        data: { file },
        headers: { "Content-Type": "multipart/form-data" }
      })
   return response.data
})
export const removeInsuranceFile = createAsyncThunk('appFiles/removeInsuranceFile', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeFile', value: id}, {typeOwner: 'CAR_INSURANCE'}))
   return {id, status: response.status}
})

// **
export const removeFilePlaceholder = createAsyncThunk('appFiles/removeFilePlaceholder', ({owner, var_name, value}) => {
   return {owner, var_name, value}
})
export const addStorageFile =  createAsyncThunk('appFiles/addStorageFile', ({file, module}) => {
   return {file, module}
})
export const removeStorageFile =  createAsyncThunk('appFiles/removeStorageFile', ({id, module}) => {
   return {id, module}
})

export const appFilesSlice = createSlice({
   name: 'appFiles',
   initialState: {
      data: {
         cars: [],
         customers: [],
         insurances: []
      }
   },
   reducers: {},
   extraReducers: builder => {
      builder
         .addCase(addUserAvatar.fulfilled, (state, action) => {
            if (action.payload.file !== undefined) {
               const userData = JSON.parse(localStorage.getItem('userData'))
               localStorage.setItem('userData', JSON.stringify({...userData, avatar: action.payload.file}))
               window.dispatchEvent(new Event("storage"))
            }
         })
         .addCase(getUserAvatar.fulfilled, (state, action) => {
            if (action.payload[0] !== undefined) {
               const userData = JSON.parse(localStorage.getItem('userData'))
               localStorage.setItem('userData', JSON.stringify({...userData, avatar: action.payload[0]}))
            }
         })
         .addCase(getCustomerAvatar.fulfilled, (state, action) => {
            if (action.payload !== undefined) {
               const userData = JSON.parse(localStorage.getItem('userData'))
               localStorage.setItem('userData', JSON.stringify({...userData, avatar: action.payload}))
            }
         })
         .addCase(removeUserAvatar.fulfilled, (state, action) => {
            if (action.payload.status === 200) {
               const userData = JSON.parse(localStorage.getItem('userData'))
               localStorage.setItem('userData', JSON.stringify({...userData, avatar: undefined}))
               window.dispatchEvent(new Event("storage"))
            }
         })
         .addCase(getCustomerFile.fulfilled, (state, action) => {
            const res = state.data.customers.find(elm => elm.file_id === action.payload.file_id)
            if (res === undefined) {
               state.data.customers = [...state.data.customers, {...action.payload}]
            }
         })
         .addCase(getCustomerFilesByParams.fulfilled, (state, action) => {
            action.payload.map(item => {
               const res = state.data.customers.find(elm => elm.customer_id === item.customer_id)
               if (res === undefined) {
                  state.data.customers = [...state.data.customers, {...item}]
               }
            })
         })
         .addCase(getCarFile.fulfilled, (state, action) => {
            const res = state.data.cars.find(elm => elm.file_id === action.payload.file_id)
            if (res === undefined) {
               state.data.cars = [...state.data.cars, {...action.payload}]
            }
         })
         .addCase(getCarFilesByParams.fulfilled, (state, action) => {
            action.payload.map(item => {
               const res = state.data.cars.find(elm => elm.car_id === item.car_id)
               if (res === undefined) {
                  state.data.cars = [...state.data.cars, {...item}]
               }
            })
         })
         .addCase(removeFilePlaceholder.fulfilled, (state, action) => {
            state.data[action.payload.owner] = state.data[action.payload.owner].filter(item => (item[action.payload.var_name] !== action.payload.value && item.content === ''))
         })
         .addCase(getInsuranceFilesByParams.fulfilled, (state, action) => {
            state.data.insurances = [...state.data.insurances, ...action.payload]
         })
         .addCase(removeInsuranceFile.fulfilled, (state, action) => {
            state.data.insurances = state.data.insurances.filter(item => item.file_id !== action.payload.id)
         })
         .addCase(addCustomersFilePlaceholder.fulfilled, (state, action) => {
            state.data.customers = [...state.data.customers, {...action.payload}]
         })
         .addCase(addCarsFilePlaceholder.fulfilled, (state, action) => {
            state.data.cars = [...state.data.cars, {...action.payload}]
         })
         .addCase(addStorageFile.fulfilled, (state, action) => {
            if (action.payload.file.customer_id !== undefined) {
               state.data[action.payload.module] = state.data[action.payload.module].filter(item => item.customer_id !== action.payload.file.customer_id)
            }
            if (action.payload.file.car_id !== undefined) {
               state.data[action.payload.module] = state.data[action.payload.module].filter(item => item.car_id !== action.payload.file.car_id && item.ttl === false)
            }
            state.data[action.payload.module] = [...state.data[action.payload.module], {...action.payload.file}]
         })
         .addCase(removeStorageFile.fulfilled, (state, action) => {
            state.data[action.payload.module] = state.data[action.payload.module].filter(item => item.file_id !== action.payload.id)
         })
   }
})

export default appFilesSlice.reducer
