// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import { apiLink } from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

export const getCustomer = createAsyncThunk('appCustomers/getCustomer', async id => {
   const response = await axios.get(apiLink({name: 'getCustomer', value: id}))
   return response.data
})

export const resetPassword = createAsyncThunk('appCustomers/resetPassword', async (data) => {
   const response = await axios.post(apiLink({name: 'resetPassword'}), data)
   return response.status
})

// Get Deposits
export const getDeposits = createAsyncThunk('appCustomers/getDeposits', async (customer_id) => {
   const response = await axios.get(apiLink('getDepositsList', {customer_id}))
   return response.data.deposits
})

// Media files
export const getMedia = createAsyncThunk('appCustomers/getMedia', async ({customer_id, types}) => {
   const response = await axios.post(apiLink('getFiles', {typeOwner: 'CUSTOMER'}), {
      ids: [customer_id],
      types
   })
   return {files: response.data.files, customer_id}
})
export const getCustomerFile =  createAsyncThunk('appFiles/getCustomerFile', async ({id, type, owner}) => {
   const response = await axios.get(apiLink({name: 'getFile', value: id}, {type, typeOwner: owner}))
   return response.data.file
})

// ** Accidents
export const getAccidents = createAsyncThunk('appCustomers/getAccidents', async (customer_id) => {
   const response = await axios.get(apiLink('getAccidentsList', {customer_id}))
   return response.data.roadAccidents
})

export const appCustomersSlice = createSlice({
  name: 'appCustomers',
  initialState: {
    media: [],
    deposits: [],
    accidents: [],
    selectedCustomer: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCustomer.fulfilled, (state, action) => {
         state.selectedCustomer = action.payload
      })
      .addCase(getDeposits.fulfilled, (state, action) => {
         state.deposits = action.payload
      })
      .addCase(getCustomerFile.fulfilled, (state, action) => {
         const res = state.media.find(elm => elm.file_id === action.payload.file_id)
         if (res === undefined) {
            state.media = [...state.media, {...action.payload}]
         }
      })
      .addCase(getMedia.fulfilled, (state, action) => {
         const uniqueMedia = [...state.media, ...action.payload.files].reduce((accumulator, current) => {
            if (!accumulator.find((item) => item.file_id === current.file_id)) {
               accumulator.push(current)
            }
            return accumulator
         }, [])
         state.media = uniqueMedia.filter((item) => item.customer_id === action.payload.customer_id)
      })
      .addCase(getAccidents.fulfilled, (state, action) => {
         state.accidents = action.payload
      })
  }
})

export default appCustomersSlice.reducer
