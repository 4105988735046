// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Import server Config
import apiLink from '@configs/serverConfig'

// ** Axios Imports
import axios from 'axios'

// ** Credit
export const getCreditsList = createAsyncThunk('appCredits/getCreditsList', async params => {
   params.page = params.page === 0 ? 0 : params.page - 1
   const response = await axios.get(apiLink('getCreditsList', params))

   return {
      params,
      data: response.data.credits,
      totalPages: response.data.total_elements
   }
})
export const getCredit = createAsyncThunk('appCredits/getCredit', async id => {
   const response = await axios.get(apiLink({name: 'getCredit', value: id}))
   return response.data
})
export const addCredit = createAsyncThunk('appCredits/addCredit', async (data) => {
   const response = await axios.post(apiLink('addCredit'), data)
   return response.data
})
export const getCreditPeriods = createAsyncThunk('appCredits/getCreditPeriods', async id => {
   const response = await axios.get(apiLink({name: 'getCreditPeriods', value: id}))
   return response.data
})
export const crediStatusAnnulled = createAsyncThunk('appCredits/crediStatusAnnulled', async id => {
   const response = await axios.post(apiLink({name: 'changeCreditStatus', value: id}, {status: 'ANNULLED'}))
   return response.data
})
export const crediStatusSeized = createAsyncThunk('appCredits/crediStatusSeized', async id => {
   const response = await axios.post(apiLink({name: 'changeCreditStatus', value: id}, {status: 'SEIZED'}))
   return response.data
})
export const recalculateCredit = createAsyncThunk('appCredits/recalculateCredit', async id => {
   const response = await axios.post(apiLink({name: 'recalculateCredit', value: id}))
   return response.data
})
export const calculateCredit = createAsyncThunk('appCredits/calculateCredit', async id => {
   const response = await axios.post(apiLink({name: 'calculateCredit', value: id}))
   return response.data
})
export const calculateTotalAmount = createAsyncThunk('appCredits/calculateTotalAmount', async (data) => {
   const response = await axios.post(apiLink('calculateTotalAmount'), data)
   return response.data
})

// ** Payments
export const addPayment = createAsyncThunk('appCredits/addPayment', async (data) => {
   return await axios.post(apiLink('addPayment'), data)
})
export const removeCreditPayment = createAsyncThunk('appCredits/removeCreditPayment', async (id) => {
   const response = await axios.delete(apiLink({name: 'removePayment', value: id}, {type: 'CREDIT'}))
   return response.data
})

// ** First payment payments
export const getFirstPayemntPayments = createAsyncThunk('appCredits/getFirstPayemntPayments', async id => {
   const response = await axios.get(apiLink({name: 'getFirstPayemntPayments', value: id}))
   return response.data
})
export const removeFirstPaymentPayment = createAsyncThunk('appCredits/removeFirstPaymentPayment', async (id) => {
   const response = await axios.delete(apiLink({name: 'removePayment', value: id}, {type: 'FIRST_PAYMENT_CREDIT'}))
   return response.data
})

// ** Deposts
export const removeDepositPayment = createAsyncThunk('appCredits/removeDepositPayment', async (id) => {
   const response = await axios.delete(apiLink({name: 'removePayment', value: id}, {type: 'DEPOSIT'}))
   return response.data
})

// ** Customers list
export const getCreditsCustomers = createAsyncThunk('appCredits/getCreditsCustomers', async () => {
   const params = {sort_list: 'first_name', sort_order: 'ASC', rows: 1000}
   const response = await axios.get(apiLink('getCustomersShortList', params))
   return response.data.customers
})
// ** Cars list
export const getCreditsCars = createAsyncThunk('appCredits/getCreditsCars', async () => {
   const params = {sort_list: 'id', sort_order: 'ASC', rows: 1000, has_customer: false, in_road_accident_lock: false}
   const response = await axios.get(apiLink('getCarsShortList', params))
   return response.data.cars
})

// ** Credit Settings
export const getCreditSettings = createAsyncThunk('appCredits/getCreditSettings', async () => {
   const response = await axios.get(apiLink('getCreditSettings'))
   return response.data.creditSettings
})
export const addCreditSettings = createAsyncThunk('appCredits/addCreditSettings', async (data) => {
   delete data.id
   const response = await axios.post(apiLink('addCreditSettings'), data)
   return response.data
})
export const updateCreditSettings = createAsyncThunk('appCredits/updateCreditSettings', async (data) => {
   const preset_id = data.id
   delete data.id
   const response = await axios.put(apiLink({name: 'updateCreditSettings', value: preset_id}), data)
   return response.data
})
export const removeCreditSettings = createAsyncThunk('appCredits/removeCreditSettings', async (id) => {
   const response = await axios.delete(apiLink({name: 'removeCreditSettings', value: id}))
   return response.data
})

export const appCreditsSlice = createSlice({
  name: 'appCredits',
  initialState: {
    data: [],
    total: 1,
    params: {},
    settings: [],
    selectedCredit: null,
    customers: [],
    cars: []
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getCreditsList.fulfilled, (state, action) => {
         state.data = action.payload.data
         state.params = action.payload.params
         state.total = action.payload.totalPages
      })
      .addCase(getCredit.fulfilled, (state, action) => {
         state.selectedCredit = action.payload
      })
      .addCase(getCreditSettings.fulfilled, (state, action) => {
         state.settings = action.payload
      })
      .addCase(updateCreditSettings.fulfilled, (state, action) => {
         state.settings = action.payload
      })
      .addCase(getCreditsCustomers.fulfilled, (state, action) => {
         state.customers = action.payload
      })
      .addCase(getCreditsCars.fulfilled, (state, action) => {
         state.cars = action.payload
      })
  }
})

export default appCreditsSlice.reducer
