/**
 ** List of params for post URL
*/
export const createParamsListForTable = (params) => {
   // ** Remove empty parameters
   Object.keys(params).forEach((k) => params[k] === '' && delete params[k])

   const uri = new URLSearchParams(params).toString()

   return `?${uri}`
}
