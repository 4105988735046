// ** I18n Imports
import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import { initReactI18next } from 'react-i18next'
//import LanguageDetector from 'i18next-browser-languagedetector'

// ** Detect saved language after refresh
let lsLanguage = localStorage.getItem('i18nextLng')

// ** Set default language if not set
if (lsLanguage === null) {
   lsLanguage = 'ua'
   localStorage.setItem('i18nextLng', lsLanguage)
}

// I18n init
i18n
  // Enables the i18next backend
  .use(Backend)

  // Enables the hook initialization module
  .use(initReactI18next)
  .init({
      lng: lsLanguage,
      backend: {
         /* translation file path */
         loadPath: `${process.env.PUBLIC_URL}/assets/data/locales/{{lng}}.json`
      },
      fallbackLng: 'en',
      debug: false,
      keySeparator: false,
      react: {
        useSuspense: false
      },
      interpolation: {
        escapeValue: false,
        formatSeparator: ','
      }
  })

export default i18n
