// ** Reducers Imports
import auth from './authentication'
import cars from '@src/views/cars/store'
import rents from '@src/views/rent/store'
import files from '@src/views/files/store'
import navbar from './navbar'
import layout from './layout'
import credits from '@src/views/credit/store'
import currency from '@src/@core/layouts/components/navbar/store'
import customers from '@src/views/customer/store'
import accidents from '@src/views/accidents/store'

const rootReducer = {
  auth,
  cars,
  rents,
  files,
  navbar,
  layout,
  credits,
  currency,
  customers,
  accidents
}

export default rootReducer
