// ** Import Functions
import { createParamsListForTable } from '@src/utility/helpFunctions'

// Server config
let serverConfig = false

if (process.env.REACT_APP_NODE_ENV === 'development') {
   serverConfig = {
      path: 'http://167.99.252.5:8080',
      api:  'http://167.99.252.5:8080/api/customer/v1'
   }
}
if (process.env.REACT_APP_NODE_ENV === 'production') {
   serverConfig = {
      path: 'http://209.38.252.69:8080',
      api:  'http://209.38.252.69:8080/api/customer/v1'
   }
}


// Endpoints list
const endPoints = {
   getUserProfileDetails: '/profile',

   getCustomer: '/profile',
   resetPassword: '/profile/reset-password',

   getCompany: '/company',
   updateCompany: '/company',
   getPermissions: '/roles/permissions',
   addPermission: '/roles',
   updatePermission: '/roles/{value}',
   removePermission: '/roles/{value}',
   getEmployees: '/users',
   addEmployee: '/users',
   updateEmployee: '/users/{value}',
   getProfile: '/profile',
   updateProfile: '/profile',
   profileUploadAvatar: '/files/{value}',

   getCarsList: '/cars',
   addCar: '/cars',
   getCarsShortList: '/cars/short-list',
   getCarBrands: '/cars/brands',
   getCar: '/cars/{value}',
   updateCar: '/cars/{value}',
   removeCar: '/cars/{value}',
   carUploadFile: '/files/{value}',

   getCreditsList: '/credits',
   addCredit: '/credits',
   getCredit: '/credits/{value}',
   getCreditPeriods: '/credits/{value}/periods',
   getCreditSettings: '/credits/settings',
   addCreditSettings: '/credits/settings',
   updateCreditSettings: '/credits/settings/{value}',
   removeCreditSettings: '/credits/settings/{value}',
   getFirstPayemntPayments: '/credits/{value}/first-payments',
   changeCreditStatus: '/credits/{value}',
   recalculateCredit: '/credits/{value}/recalculate',
   calculateCredit: '/credits/{value}/calculate',
   calculateTotalAmount: '/credits/calculate-total-amount',

   getRentsList: '/rents',
   addRent: '/rents',
   getRent: '/rents/{value}',
   stopRent: '/rents/{value}',
   addRentPeriodDetails: '/rents/{value}/period-info',
   closeRentPeriod: '/rents/{value}/close-period',
   createRentPeriod: '/rents/{value}/create-next-period',
   getRentSettings: '/rents/settings',
   addRentSettings: '/rents/settings',
   updateRentsettings: '/rents/settings/{value}',
   removeRentSettings: '/rents/settings/{value}',
   carReplacement: '/rents/{value}/car-replacement',

   addPayment: '/payments/create',
   removePayment: '/payments/refund/{value}',

   getExchangeRate: '/exchange-rate',
   updateExchangeRate: '/exchange-rate/{value}',

   getDepositsList: '/deposits',

   getInsurancesList: '/cars/insurance',
   getInsurance: '/cars/insurance/{value}',
   addInsurance: '/cars/insurance',
   removeInsurance: '/cars/insurance/{value}',

   getFile: '/files/{value}',
   getFiles: '/files',
   uploadFile: '/files/{value}',
   removeFile: '/files/{value}',

   getAccidentsList: '/road-accidents',
   addAccident: '/road-accidents',
   getAccident: '/road-accidents/{value}',
   updateAccident: '/road-accidents/{value}',
   completeAccident: '/road-accidents/{value}/complete',
   addAccidentInsuranceAppeal: '/road-accidents/{value}/insurance-appeal',
   updateAccidentInsuranceAppeal: '/road-accidents/insurance-appeal/{value}',
   addAccidentInsuranceAppealExpectedPayment: '/road-accidents/insurance-appeal/{value}/expected-payment',
   removeAccidentInsuranceAppealEexpectedPayment: '/road-accidents/insurance-appeal/expected-payment/{value}',
   updateAccidentInsuranceAppealExpertExamined: '/road-accidents/insurance-appeal/{value}/expert-examined'
}

export const ttl_delay = 10// mins

export const checkTtlExpired = (ttl) => {
   const result = Math.ceil((new Date().getTime() - ttl.getTime()) / (1000 * 60 * 1))
   return result > ttl_delay
}

export const pathLink = (endPoint) => {
   return serverConfig.path + endPoints[endPoint.name]
}

export const apiLink = (endPoint, params = null) => {

   const uri = params !== null ? createParamsListForTable(params) : ''
   if (typeof endPoint === 'object') {
      if (endPoint.values !== undefined) {

         let path = endPoints[endPoint.name]

         endPoint.values.map((item, index) => {
            path = path.replace(`{value${index + 1}}`, item)
         })

         return serverConfig.api + path + uri
      }
      return serverConfig.api + endPoints[endPoint.name].replace('{value}', endPoint.value) + uri
   } else {
      return serverConfig.api + endPoints[endPoint] + uri
   }

}

export { serverConfig }

export default apiLink
